import { useEffect } from 'react';



const Redirect = ({ navigate, user }) => {
  useEffect(() => {
    if (user?.companyType[0] === 'RTI' && user?.is_customer) {
      navigate(`/${user.type}/material-request/`);
      // navigate(`/${user.type}/audit-access/`);
    } else if (user?.companyType[0] === 'Inventory' && user?.is_vendor) {
      navigate(`/${user.type}/purchase-orders/`);
    } else if (user?.companyType[0] === 'WMS' && user?.is_vendor) {
      navigate(`/${user.type}/purchase-orders/`);
    } else {
      navigate(`/${user.type}/dashboard/`);
    }
  }, [user.type, navigate]);
  return null;
};

export default Redirect;
