import React, { useEffect, useState } from 'react';
import { navigate, Router } from '@reach/router';
import { connect, useDispatch } from 'react-redux';
import {
  publicRoutes,
  RTIRoutes,
  superUserRoutes,
  extraRoutesEmployee,
  extraRoutesSuperUser,
  outerRoutesEmployee,
  outerRoutesSuperUser,
  RTICustomerRoutes,
  RTIVendorRoutes,
} from 'constants/routes';
import { PrivateRoutes } from 'components/PrivateRoutes';
import { NotFound404Screen } from 'screens/404.screen';
import { loadAPI } from 'common/helpers/api';
import { signOutUser } from 'common/actions/signIn';
import LogoLoading from './Templates/LogoLoading';

const RootRouter = ({ user }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [companyKey, setCompanyKey] = useState(Math.random());
  
  useEffect(() => {
    const fetchMenu = async () => {
      setLoading(true);
      const {
        data: companyProfileData,
        error,
        loading: cmpnyLoading,  
      } = await loadAPI(`/company-profile/${user.companyId}/`);
      setCompanyProfile(companyProfileData);
      setCompanyKey(Math.random());

      if (error) {
        await dispatch(signOutUser());
        await navigate('/');
      }

      setLoading(false);
    };

    if (user.companyId) fetchMenu();
    else if (user && !user.companyId) setLoading(false);
  }, [user]);

  if (loading) {
    return <LogoLoading />;
  }
  const uniqueCompanyKey = user?.companyType[0];
  if (user) {
    switch (uniqueCompanyKey) {
      // case user.type === 'public':
      case 'public':
        return (
          <div key={String(companyKey)}>
            <Router>
              {(publicRoutes || []).map((Route, index) => {
                if (
                  !Route.key ||
                  ({
                    ...companyProfile,
                    adjustment_inventory: companyProfile?.inventory,
                  } &&
                    companyProfile &&
                    companyProfile[Route.key])
                ) {
                  return (
                    <Route.Component path={Route.path} key={index.toString()} />
                  );
                }
              })}
              <NotFound404Screen default />
            </Router>
          </div>
        );

      case 'RTI':
        if (user?.isAdmin) { 
          return (
            <div key={String(companyKey)}>
              <PrivateRoutes
                companyProfile={{
                  ...companyProfile,
                  adjustment_inventory: companyProfile?.inventory,
                }}
                routes={[
                  ...RTIRoutes,
                  ...(user.isAdmin ? superUserRoutes : []),
                ]}
                extraRoutes={[
                  ...(user.isAdmin ? extraRoutesSuperUser : []),
                  ...extraRoutesEmployee,
                ]}
                outerRoutes={[
                  ...(user.isAdmin ? outerRoutesSuperUser : []),
                  ...outerRoutesEmployee,
                ]}
                user={user}
              />
            </div>
          );
        }

        if (user?.isEmployee) {
          return (
            <div key={String(companyKey)}>
              <PrivateRoutes
                companyProfile={{
                  ...companyProfile,
                  // adjustment_inventory: companyProfile?.inventory,
                }}
                routes={[
                  ...RTIRoutes,
                  ...(user.isAdmin ? superUserRoutes : []),
                ]}
                extraRoutes={[
                  ...(user.isAdmin ? extraRoutesSuperUser : []),
                  ...extraRoutesEmployee,
                ]}
                outerRoutes={[
                  ...(user.isAdmin ? outerRoutesSuperUser : []),
                  ...outerRoutesEmployee,
                ]}
                user={user}
              />
            </div>
          );
        }
        if (user?.is_customer) {
          return (
            <div key={String(companyKey)}>
              <PrivateRoutes
                routes={[...RTICustomerRoutes]}
                extraRoutes={[...extraRoutesEmployee]}
                outerRoutes={[...outerRoutesEmployee]}
                user={user}
              />
            </div>
          );
        }
        if (user?.is_vendor) {
          return (
            <div key={String(companyKey)}>
              <PrivateRoutes
                routes={[...RTIVendorRoutes]}
                extraRoutes={[...extraRoutesEmployee]}
                user={user}
              />
            </div>
          );
        }
        return null;

      default:
        return (
          <div key={String(companyKey)}>
            <Router>
              {publicRoutes.map((Route, index) => {
                if (
                  !Route.key ||
                  ({
                    ...companyProfile,
                    adjustment_inventory: companyProfile?.inventory,
                  } &&
                    companyProfile[Route.key])
                ) {
                  return (
                    <Route.Component path={Route.path} key={index.toString()} />
                  );
                }
              })}
              <NotFound404Screen default />
            </Router>
          </div>
        );
    }
  } else {
    return <LogoLoading />;
  }
};

const mapStateToProps = (state) => {
  return { user: state.user.userMeta };
};

export default connect(mapStateToProps)(RootRouter);
