import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import ScreenWrapper from 'components/ScreenWrapper';
import Redirect from 'components/Redirect';
import { NotFound404Screen } from 'screens/404.screen';
import { useDispatch } from 'react-redux';
import useModuleConfig from 'hooks/useModuleConfig';
import { ConfigProvider, theme } from 'antd';
import { useState } from 'react';
import { loadAPI } from 'common/helpers/api';
import { retrieveThemeConfig } from 'common/api/auth';

export const PrivateRoutes = ({
  routes,
  extraRoutes,
  user,
  outerRoutes,
  companyProfile,
}) => {
  const dispatch = useDispatch();
  // console.log(dispatch() );

  const { moduleConfig, getUserModuleConfig } = useModuleConfig({
    formKey: '',
  });
  console.log({ moduleConfig }, { getUserModuleConfig });

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('darkMode')
  );
  const [themeConfigToken, setThemeConfigToken] = useState(null);

  const [themeId, setThemeId] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const { data: user } = await loadAPI(`user/meta/`);

      if (user) {
        setThemeId(user?.theme_id);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetchThemeConfigData = async (id) => {
      const { data } = await retrieveThemeConfig(id);
      setThemeConfigToken(data);
      const themeConfigDataString = JSON.stringify(data);
      localStorage.setItem('themeConfigData', themeConfigDataString);
    };
    if (themeId) {
      fetchThemeConfigData(themeId);
    }
  }, [themeId]);

  // useEffect(() => {
  //   getUserModuleConfig();
  // }, []);
  const getTypes = (user) => {
    if (user.companyType[0] === 'RTI') {
      return `/${user?.companyType[0]}/`;
    } else return '/employee/';
  };

  return (
    <ConfigProvider
      theme={{
        token:
          localStorage?.getItem('themeConfigData') != undefined
            ? JSON.parse(localStorage.getItem('themeConfigData') || {})
            : themeConfigToken,
        algorithm: isDarkMode === '1' ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <Router>
        <Redirect path="/" user={user} />
        {outerRoutes
          ? outerRoutes.map((Route, index) => {
              return (
                <Route.Component
                  path={`/${user?.type}${Route.path}`}
                  key={index.toString()}
                  {...Route.props}
                />
              );
            })
          : null}
        <ScreenWrapper
          moduleConfig={moduleConfig}
          path={`/${user.type}/`}
          // path={getTypes(user)}
          routes={routes}
          companyProfile={companyProfile}
        >
          {routes.map((Route, index) => {
            if (
              !Route.key ||
              (moduleConfig && moduleConfig[Route.key]) ||
              (companyProfile && companyProfile[Route.key])
            ) {
              return (
                <Route.Component
                  path={`${Route.path}`}
                  key={index.toString()}
                  {...Route.props}
                />
              );
            }
          })}
          {routes.map((Route) => {
            if (
              !Route.key ||
              (moduleConfig && moduleConfig[Route.key]) ||
              (companyProfile && companyProfile[Route.key])
            ) {
              return Route.subMenu
                ? Route.subMenu.map((ChildRoute, index) => {
                    if (
                      !ChildRoute.key ||
                      (moduleConfig && moduleConfig[ChildRoute.key]) ||
                      (companyProfile && companyProfile[ChildRoute.key])
                    ) {
                      return (
                        <ChildRoute.Component
                          path={`${ChildRoute.path}`}
                          key={index.toString()}
                          {...ChildRoute.props}
                        />
                      );
                    }
                  })
                : null;
            }
          })}
          {extraRoutes
            ? extraRoutes.map((Route, index) => {
                if (
                  !Route.key ||
                  (moduleConfig && moduleConfig[Route.key]) ||
                  (companyProfile && companyProfile[Route.key])
                ) {
                  return (
                    <Route.Component
                      path={`${Route.path}`}
                      key={index.toString()}
                      {...Route.props}
                    />
                  );
                }
              })
            : null}
          <NotFound404Screen default />
        </ScreenWrapper>
      </Router>
    </ConfigProvider>
  );
};
