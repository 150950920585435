import './App.css';
import 'antd/dist/reset.css';
import '@ant-design/flowchart/dist/index.css';

import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'helpers/shared';

import { store, persistor } from 'common/reducers';
import themes from 'constants/theme';
import { Loading } from 'components/Loading';
import RootRouter from 'components/RootRouter';
import { ConfigProvider, theme } from 'antd';
import { useAPI } from 'common/hooks/api';
import { retrieveThemeConfig } from 'common/api/auth';
import { loadAPI } from 'common/helpers/api';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<Loading />}>
          <RootRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
