import React from 'react';
import { Typography } from 'antd';
import logo from '../../icons/logo-img.gif';

const LogoLoading = ({ style }) => {
  return (
    <div className="column h-100 w-100 align-center justify-center" style={{
      background:`${localStorage.getItem('darkMode')==="1"?"black":""}`
    }}>
      <div className="m-2">
        <img src={logo} alt="Loading..." width={50} />
      </div>
      <Typography.Text >Loading...</Typography.Text>
    </div>
  );
};

export default LogoLoading;
