export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const ADD_PFEP_DATA = 'ADD_PFEP_DATA';
export const ADD_PFEP_BASIC_DATA = 'ADD_PFEP_BASIC_DATA';
export const CLEAN_PFEP_DATA = 'CLEAN_PFEP_DATA';
export const ADD_CREATE_CP_DATA = 'ADD_CREATE_CP_DATA';
export const ADD_CREATE_CP_BASIC_DATA = 'ADD_CREATE_CP_BASIC_DATA';
export const CLEAN_CREATE_CP_DATA = 'CLEAN_CREATE_CP_DATA';
export const START_STEP_LOADING = 'START_STEP_LOADING';
export const STOP_STEP_LOADING = 'STOP_STEP_LOADING';
export const PAGE_SIZE_CHANGE = 'PAGE_SIZE_CHANGE'
export const CHANGE_USER_VIEW = 'CHANGE_USER_VIEW'
